import React from 'react'
/** @jsx jsx */
import { Styled, jsx } from 'theme-ui'
import Flex from './Flex'

const Call = () => {
  return (
    <Flex
      sx={{
        bg: `background`,
        maxWidth: [`100%`, `400px`],
        py: [1, 2, 3],
        px: [3, 4, 5],
        color: `text`,
      }}
    >
      <table sx={{ m: 0, border: 0 }}>
        <tr>
          <td
            sx={{
              display: [`none`, `table-cell`],
              border: 0,
              pr: [1, 2, 3],
            }}
          >
            Tel:
          </td>
          <td sx={{ pl: 0, border: 0 }}>
            <Styled.a href="tel:+4969240031700" rel="noreferrer noopener">
              +49 69 24003170-0
            </Styled.a>
          </td>
        </tr>
        <tr>
          <td
            sx={{
              display: [`none`, `table-cell`],
              border: 0,
              pr: [1, 2, 3],
            }}
          >
            Mail:
          </td>
          <td sx={{ pl: 0, border: 0 }}>
            <Styled.a href="mailto:info@ra-vilmar.de" rel="noreferrer noopener">
              info@ra-vilmar.de
            </Styled.a>
          </td>
        </tr>
      </table>
    </Flex>
  )
}

export default Call
