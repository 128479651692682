import React from 'react'
import PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { Heading } from '@theme-ui/components'
import Flex from './Flex'
import Tile from './Tile'

const propTypes = {
  height: PropTypes.string,
  minHeight: PropTypes.string,
  padding: PropTypes.string,
  bg: PropTypes.string,
  children: PropTypes.node,
  fluid: PropTypes.object,
  fluidDark: PropTypes.object,
  alignItems: PropTypes.string,
}

const defaultProps = {
  //height: [`50vh`, `62vh`, `75vh`],
  height: [`85vh`, `85vh`, `65vh`, `65vh`],
  minHeight: `320px`,
  padding: [3, 3, 5],
  bg: 'gray',
  children: null,
  alignItems: `flex-end`,
}

const Hero = ({
  height,
  minHeight,
  alignItems,
  bg,
  fluid,
  fluidDark,
  padding,
  children,
  ...props
}) => {
  const { colorMode } = useThemeUI()

  return (
    <Flex
      large
      padding={0}
      sx={{
        mb: [2, 3],
        bg: bg,
      }}
    >
      <Tile
        fluid={colorMode === `default` ? fluid : fluidDark ? fluidDark : fluid}
        sx={{
          display: `flex`,
          height: height,
          minHeight: minHeight,
          alignItems: alignItems,
        }}
        {...props}
      >
        {children}
      </Tile>
    </Flex>
  )
}

Hero.propTypes = propTypes
Hero.defaultProps = defaultProps

export default Hero
