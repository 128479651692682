import React from 'react'
import { Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Flex from '../components/Flex'
import Grid from '../components/Grid'
import Image from '../components/Image'
import Container from '../components/Container'
import Call from '../components/Call'
import SEO from '../components/Seo'
import PressList from '../components/PressList'

const propTypes = {
  data: PropTypes.object,
}

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />

    <Container smallplus>
      <p sx={{ mb: 2 }}>Herzlich Willkommen</p>
      <h1 sx={{ hyphens: `manual` }}>
        Friederike E.L. Vilmar
        <br />
        Ihre Fachanwältin für Strafrecht
      </h1>
    </Container>

    <Hero
      fluid={data.heroImage01.childImageSharp.gatsbyImageData}
      //height={[`85vh`,`85vh`,`65vh`,`65vh`]}
      bg={`primary`}
      sx={{
        color: `white`,
      }}
    >
      <Call sx={{ p: 0 }} />
    </Hero>

    <Flex smallplus sx={{ flexDirection: `column`, pb: 0 }}>
      <h2>Wir garantieren</h2>
      <ul>
        <li>Kurzfristige Erreichbarkeit,</li>
        <li>individuelle Beratung,</li>
        <li>absolute Verschwiegenheit und Diskretion</li>
      </ul>
      <p>Vertrauen Sie unserer langjährigen Erfahrung</p>
      <p sx={{ mb: 0 }}>Gerne beraten wir Sie auch auf Englisch oder Französisch.</p>
    </Flex>

    <Container smallplus sx={{ pb: 0 }}>
      <h2 sx={{ verticalAlign: `middle` }}>Aktuelle Pressemeldungen</h2>
      <PressList pressEntries={data.pressEntries.edges} />
      <div sx={{ mt: 4 }}>
        <Link
          to="/presse/"
          sx={{
            color: `primary`,
            textDecoration: `none`,
          }}
        >
          Weitere Meldungen finden Sie in dem Bereich Presse ...
        </Link>
      </div>
    </Container>

    <Flex smallplus sx={{}}>
      <h2 sx={{ verticalAlign: `middle` }}>Bewertungen</h2>
    </Flex>

    <Flex smallplus sx={{ flexDirection: `column`, py: 0 }}>
      <Image fluid={data.ratings.childImageSharp.gatsbyImageData} />
    </Flex>
  </Layout>
)

IndexPage.propTypes = propTypes

export default IndexPage

export const query = graphql`
  {
    heroData: sanityHero(usage: { eq: "Startseite" }) {
      claim
      heroImageDefault {
        asset {
          gatsbyImageData(width: 1600, layout: CONSTRAINED)
        }
      }
      heroImageDark {
        asset {
          gatsbyImageData(width: 1600, layout: CONSTRAINED)
        }
      }
    }
    heroImage01: file(relativePath: { eq: "rav/hans-martin-wtdWgEpOfKU-unsplash.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2400, layout: CONSTRAINED)
      }
    }
    bewertungen: file(relativePath: { eq: "rav/Bewertungen.png" }) {
      childImageSharp {
        gatsbyImageData(width: 2400, layout: CONSTRAINED)
      }
    }
    ratings: file(relativePath: { eq: "rav/GoogleRatings_202108.png" }) {
      childImageSharp {
        gatsbyImageData(width: 2400, layout: CONSTRAINED)
      }
    }
    pressEntries: allSanityPress(
      filter: { active: { eq: true }, mediaSource: { mediaName: { ne: null } } }
      sort: { fields: [releaseDate], order: [DESC] }
      limit: 5
    ) {
      edges {
        node {
          headline
          releaseDate(locale: "DE", formatString: "DD.MM.YYYY")
          pressLink
          active
          mediaSource {
            mediaName
            mediaLogo {
              asset {
                gatsbyImageData(width: 40, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`
